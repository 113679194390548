const passwordToggleButtons: HTMLCollectionOf<Element> = document.getElementsByClassName('js-btn-password-toggle');
const passwordValidators: HTMLCollectionOf<Element> = document.getElementsByClassName('js-password-validator');

const validatePassword = function (passwordFieldset: HTMLElement): void {
    const passwordField: HTMLInputElement = passwordFieldset.querySelector('input') as HTMLInputElement;
    const helperTextElement: HTMLElement | null = passwordFieldset.querySelector('.js-password-validator-helper-text');
    if (!helperTextElement) {
        return;
    }
    const helperText = {
        charLength: helperTextElement.querySelector('.js-length') as HTMLElement,
        lowercase: helperTextElement.querySelector('.js-lowercase') as HTMLElement,
        uppercase: helperTextElement.querySelector('.js-uppercase') as HTMLElement,
        special: helperTextElement.querySelector('.js-special') as HTMLElement
    };

    const pattern = {
        charLength: function (): boolean {
            return passwordField.value.length >= 8;
        },
        lowercase: function (): boolean {
            const regex = /^(?=.*[a-z]).+$/; // Lowercase character pattern
            return regex.test(passwordField.value);
        },
        uppercase: function (): boolean {
            const regex = /^(?=.*[A-Z]).+$/; // Uppercase character pattern
            return regex.test(passwordField.value);
        },
        special: function (): boolean {
            const regex = /^(?=.*[_\W]).+$/; // Special character or number pattern
            return regex.test(passwordField.value);
        }
    };

    function patternTest(pattern: boolean, response: HTMLElement): void {
        if (pattern) {
            response.classList.add('valid');
        } else {
            response.classList.remove('valid');
        }
    }

    function hasClass(el: HTMLElement, className: string): boolean {
        if (el.classList) {
            return el.classList.contains(className);
        } else {
            return new RegExp('(^| )' + className + '( |$)', 'gi').test(el.className);
        }
    }

    function checkPasswordStrength(): void {
        // Check that password is a minimum of 8 characters
        patternTest(pattern.charLength(), helperText.charLength);

        // Check that password contains a lowercase letter
        patternTest(pattern.lowercase(), helperText.lowercase);

        // Check that password contains an uppercase letter
        patternTest(pattern.uppercase(), helperText.uppercase);

        // Check that password contains a number or special character
        patternTest(pattern.special(), helperText.special);

        // Check that all requirements are fulfilled
        if (hasClass(helperText.charLength, 'valid') &&
            hasClass(helperText.lowercase, 'valid') &&
            hasClass(helperText.uppercase, 'valid') &&
            hasClass(helperText.special, 'valid')
        ) {
            passwordField.parentElement!.classList.add('valid');
        } else {
            passwordField.parentElement!.classList.remove('valid');
        }
    }

    passwordField.addEventListener('keyup', checkPasswordStrength);

    passwordField.addEventListener('focus', function () {
        helperTextElement.classList.add('active');
        checkPasswordStrength();
    });
    passwordField.addEventListener('blur', function () {
        helperTextElement.classList.remove('active');
    });
}

const initPassword = function (): void {
    [...passwordValidators].forEach((passwordValidator) => validatePassword(passwordValidator as HTMLElement));

    [...passwordToggleButtons].forEach(
        (passwordToggleButton: Element) => {

            const passwordToggleIconOn = passwordToggleButton.querySelector('.js-password-toggle-on');
            const passwordToggleIconOff = passwordToggleButton.querySelector('.js-password-toggle-off');
            const passwordField = passwordToggleButton.previousElementSibling as HTMLInputElement;
            if(!passwordField) {
                return;
            }

            passwordToggleButton.addEventListener("click", (event) => {
                event.preventDefault();

                passwordToggleIconOn.classList.toggle('hidden');
                passwordToggleIconOff.classList.toggle('hidden');

                passwordField.type = ("text" === passwordField.type) ? "password" : "text";
            });
        }
    );
}

export { initPassword };
