const carousels: HTMLCollectionOf<Element> = document.getElementsByClassName("js-carousel");
import Hammer from 'hammerjs';

const initCarousel = function (carousel: Element): void {
    const slidesContainer: HTMLElement = carousel.querySelector(".js-carousel__container") as HTMLElement;
    const firstSlide: HTMLElement = slidesContainer.querySelector(".js-carousel__slide") as HTMLElement;
    const prevButtons: HTMLCollectionOf<Element> = carousel.getElementsByClassName("js-carousel-btn-prev");
    const nextButtons: HTMLCollectionOf<Element> = carousel.getElementsByClassName("js-carousel-btn-next");

    const getSlidesContainerWidth = function (): number {
        return slidesContainer.getBoundingClientRect().width;
    }

    const changeButtonsStates = function (slidesContainerScrollLeft?: number): void {
        const slidesContainerWidth: number = getSlidesContainerWidth();

        slidesContainerScrollLeft ??= slidesContainer.scrollLeft;

        [...prevButtons].forEach(
            (prevButton: Element) => {
                (prevButton as HTMLElement).classList.toggle('disabled', slidesContainerScrollLeft <= 0);
            }
        );
        [...nextButtons].forEach(
            (nextButton: Element) => {
                (nextButton as HTMLElement).classList.toggle('disabled', slidesContainerScrollLeft >= slidesContainer.scrollWidth - slidesContainerWidth - 10);
            }
        );
    }

    const checkState = function (): void {
        // if (getSlidesContainerWidth() >= slidesContainer.scrollWidth) {
        //     carousel.classList.add('centered');
        // } else {
        //     carousel.classList.remove('centered');
        // }
        changeButtonsStates();
    }

    const initTouchEvents = function (): void {
        let touchDown: number | null = null;
        const handleTouchStart = (touchEvent: TouchEvent): void => {
            touchDown = touchEvent.touches[0].clientX;
        }
        const handleTouchMove = (touchEvent: TouchEvent): void => {
            if (touchDown === null) {
                return;
            }

            const currentTouch: number = touchEvent.touches[0].clientX;
            const diff: number = touchDown - currentTouch;

            if (diff > 5) {
                (nextButtons[0] as HTMLElement).click();
            }

            if (diff < -5) {
                (prevButtons[0] as HTMLElement).click();
            }

            touchDown = null;
        }
        carousel.addEventListener("touchstart", function (touchEvent: TouchEvent) {
            handleTouchStart(touchEvent);
        });
        carousel.addEventListener("touchmove", function (touchEvent: TouchEvent) {
            handleTouchMove(touchEvent);
        });
    }

    const initHammerTime = function (): void {
        const hammerTime = new Hammer(carousel, {});

        hammerTime.on("swipe", function (swipeEvent: HammerInput) {
            switch (swipeEvent.direction) {
                case Hammer.DIRECTION_LEFT:
                    (nextButtons[0] as HTMLElement).click();
                    break;
                case Hammer.DIRECTION_RIGHT:
                    (prevButtons[0] as HTMLElement).click();
                    break;
            }
        });
    }

    const initEvents = function (): void {
        [...nextButtons].forEach(
            (nextButton: Element) => {
                nextButton.addEventListener("click", () => {
                    const slideWidth: number = firstSlide.clientWidth + 8;
                    slidesContainer.scrollLeft += slideWidth;
                    changeButtonsStates(slidesContainer.scrollLeft + slideWidth);
                });
            }
        );

        [...prevButtons].forEach(
            (prevButton: Element) => {
                prevButton.addEventListener("click", () => {
                    const slideWidth: number = firstSlide.clientWidth + 8;
                    slidesContainer.scrollLeft -= slideWidth;
                    changeButtonsStates(slidesContainer.scrollLeft - slideWidth);
                });
            }
        );

        window.addEventListener('resize', function () {
            checkState();
        }, true);
        document.addEventListener("DOMContentLoaded", function () {
            checkState();
        });
    }

    initEvents();
    initHammerTime();
    checkState();
    setTimeout(checkState, 500);
}

const initCarousels = function (): void {
    [...carousels].forEach(
        (carousel: Element) => {
            initCarousel(carousel);
        }
    );
}

export { initCarousels };
