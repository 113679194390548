import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

const initLightboxGallery = function () {

    const lightboxes = document.getElementsByClassName('js-lightbox__container');
    if (lightboxes.length === 0) {
        return;
    }

    const lightbox = new PhotoSwipeLightbox({
        gallery: '.js-lightbox__container',
        children: 'a.js-lightbox',
        arrowKeys: true,
        arrowPrev: true,
        arrowNext: true,
        pswpModule: () => import('photoswipe'),
        bgOpacity: 0.95,
    });
    lightbox.on('uiRegister', function() {
        lightbox.pswp.ui.registerElement({
            name: 'custom-caption',
            order: 9,
            isButton: false,
            appendTo: 'root',
            html: '',
            onInit: (el) => {
                lightbox.pswp.on('change', () => {
                    const currSlideElement = lightbox.pswp.currSlide.data.element;
                    if (currSlideElement) {
                        const hiddenCaption = currSlideElement.querySelector('.hidden-caption-content');
                        if (hiddenCaption) {
                            el.classList.add('pswp__hide-on-close');
                            el.innerHTML = hiddenCaption.innerHTML || '';
                        } else {
                            el.classList.remove('pswp__hide-on-close');
                            el.innerHTML = '';
                        }
                    }
                });
            }
        });
    });
    lightbox.init();

    lightbox.on('close', () => {
        const customEvent = new CustomEvent('lightboxClosed', {detail: lightbox.pswp.currSlide.data.element});
        document.dispatchEvent(customEvent);
    });
}

export { initLightboxGallery };
