const scrollToButtons = document.getElementsByClassName('js-scroll-to-btn');
const scrollToTopButton = document.querySelector('.js-scroll-to-top');
const screenDownButton = document.getElementsByClassName('js-screen-down');

const scrollTo = function (targetId: string) {
    const targetElement = document.getElementById(targetId);
    const targetElementRect = targetElement.getBoundingClientRect();
    window.scrollTo({
        top: targetElementRect.top + window.scrollY,
        behavior: "smooth"
    });
}

const initEvents = function () {
    const requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame;
    const windowViewPortHeight = window.innerHeight;
    let isRequestingAnimationFrame = false;

    [...scrollToButtons].forEach(
        (element: Element) => {
            element.addEventListener("click", (event) => {
                event.preventDefault();
                if ((element as HTMLElement).dataset.target) {
                    scrollTo((element as HTMLElement).dataset.target);
                }
            });
        }
    );

    [...screenDownButton].forEach(
        (element: Element) => {
            element.addEventListener("click", () => {
                const windowViewScreen = Math.floor(window.scrollY / window.innerHeight) + 1;
                window.scrollTo({
                    top: window.innerHeight * windowViewScreen,
                    behavior: "smooth"
                });
            });
        }
    );

    if (!scrollToTopButton) {
        return;
    }

    scrollToTopButton.addEventListener("click", (event) => {
        event.preventDefault();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    });

    function filterGoTopButtonVisibility() {
        const windowPageYOffset = window.pageYOffset || document.documentElement.scrollTop;
        if (windowPageYOffset > windowViewPortHeight) {
            scrollToTopButton.classList.remove('hidden');
            isRequestingAnimationFrame = false;
        } else {
            scrollToTopButton.classList.add('hidden');
            requestAnimationFrame(filterGoTopButtonVisibility);
        }
    }

    window.addEventListener('scroll', function() {
        if (!isRequestingAnimationFrame) {
            requestAnimationFrame(filterGoTopButtonVisibility);
            isRequestingAnimationFrame = true;
        }
    });
}

const initPageScroll = function () {
    initEvents();
}

export { initPageScroll };
