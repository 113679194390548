const navigationItems = document.getElementsByClassName('js-navigation__item');

const setActiveNavigationItem = function (selectNavigation) {
    [...navigationItems].forEach(
        (navigationItem: HTMLElement) => {
            if ((navigationItem as HTMLElement).dataset.navigationId == selectNavigation) {
                navigationItem.classList.add('active');
            } else {
                navigationItem.classList.remove('active');
            }
        }
    );
}

const initNavigation = function () {
    if (typeof selectNavigation === 'undefined') {
        return;
    }

    if (selectNavigation > 0) {
        setActiveNavigationItem(selectNavigation);
    }
}

export { initNavigation };
