const closeButtons = document.getElementsByClassName('js-close-btn');
const confirmButtons = document.getElementsByClassName('js-confirm-btn');
const innerClickButtons = document.getElementsByClassName('js-inner-click');
const revealContainers = document.getElementsByClassName('js-container-reveal');
const previewImagesButtons = document.getElementsByClassName('js-preview-images');
const selectImageStyleButton = document.querySelector('.js-select-image-style');

const initPreviewImages = function (previewImageButton: Element) {

    let startPreviewImage = 0;
    let activePreviewImage = 0;
    let toNextPreviewImage;
    let previewImages = previewImageButton.querySelectorAll("img");

    const startPreviewImages = function () {
        clearInterval(toNextPreviewImage);
        activePreviewImage = startPreviewImage;
        if (previewImages.length <= 1) {
            return;
        }
        toNextPreviewImage = setInterval(showNextPreviewImage, 600);
    }

    const showNextPreviewImage = function (activeImageIndex) {
        if (typeof activeImageIndex !== 'undefined') {
            activePreviewImage = activeImageIndex;
            startPreviewImage = previewImages.length - activeImageIndex - 1;
        }

        activePreviewImage++;
        if (activePreviewImage >= previewImages.length) {
            activePreviewImage = 0;
        }

        previewImages.forEach(function (image) {
            image.classList.remove("show");
        });
        previewImages[activePreviewImage].classList.add("show");
    }

    const resetPreviewImages = function () {
        clearInterval(toNextPreviewImage);
        if (previewImages.length <= 1) {
            return;
        }
        previewImages.forEach(
            (image) => {
                image.classList.remove("show");
            }
        );

        previewImages[startPreviewImage].classList.add("show");
    }

    previewImageButton.addEventListener("mouseenter", (mouseEvent) => {
        mouseEvent.preventDefault();
        startPreviewImages();
    });
    previewImageButton.addEventListener("mouseleave", (mouseEvent) => {
        mouseEvent.preventDefault();
        resetPreviewImages();
    });

    return {
        showNextPreviewImage
    }
}


const initEvents = function () {
    [...confirmButtons].forEach(
        (confirmButton: Element) => {
            const confirmText = (confirmButton as HTMLElement).dataset.confirmText ?? "Are you sure?";
            confirmButton.addEventListener("click", (event) => {
                if (!confirm(confirmText)) {
                    event.preventDefault();
                }
            });
        }
    );

    [...closeButtons].forEach(
        (closeButton: Element) => {
            closeButton.addEventListener("click", (event) => {
                event.preventDefault();
                const container = (closeButton as HTMLElement).closest('.js-container');
                if (container) {
                    container.remove();
                }
            });
        }
    );

    [...innerClickButtons].forEach(
        (innerClickButton: Element) => {
            const links = (innerClickButton as HTMLElement).getElementsByTagName('a');
            if (links.length === 1) {
                (innerClickButton as HTMLElement).classList.add('cursor-pointer');
                innerClickButton.addEventListener("click", (event) => {
                    event.preventDefault();
                    location.href = links[0].getAttribute("href");
                })
            }
        }
    );

    [...revealContainers].forEach(
        (revealContainer: Element) => {
            revealContainer.addEventListener("mouseover", (event) => {
                event.preventDefault();
                (revealContainer as HTMLElement).classList.add('container-reveal');
            })

            revealContainer.addEventListener("mouseout", (event) => {
                event.preventDefault();
                (revealContainer as HTMLElement).classList.remove('container-reveal');
            })
        }
    );

    [...previewImagesButtons].forEach(
        (previewImageButton: Element) => {
            initPreviewImages(previewImageButton);
        }
    );

    if (selectImageStyleButton) {
        const radioButtons = selectImageStyleButton.querySelectorAll('input[type="radio"]') as HTMLButtonElement;
        const imageStylePreviewImagesButtons = selectImageStyleButton.nextElementSibling.querySelectorAll(
            '.js-preview-images'
        );
        [...radioButtons].forEach(
            (radioButton: Element) => {
                radioButton.addEventListener("click", () => {
                    const value = (radioButton as HTMLInputElement).value;
                    const activeImageIndex = (value === 'batman') ? 0 : 1;
                    imageStylePreviewImagesButtons.forEach(
                        (imageStylePreviewImagesButton: Element) => {
                            initPreviewImages(imageStylePreviewImagesButton).showNextPreviewImage(activeImageIndex);
                        }
                    );
                })
            }
        );
    }
}

const initCommon = function () {
    initEvents();
}

export { initCommon };
