import axios from "axios";

const promptInputs = document.getElementsByClassName('js-prompt');

const initPrompt = function () {

    let API_BASE_URL = `${import.meta.env.VITE_APP_URL}`;
    if (API_BASE_URL === 'undefined') {
        API_BASE_URL = 'https://bimbam.ai';
    }

    [...promptInputs].forEach(
        (promptInput: Element) => {

            function savePrompt() {
                axios.post(`${API_BASE_URL}/prompt/`, {'prompt': (promptInput as HTMLInputElement).value});
            }

            promptInput.addEventListener("blur", savePrompt, true);
        }
    );
}

export { initPrompt };
