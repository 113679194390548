const scrollElements = document.querySelectorAll(".js-scroll");

const elementInView = (el, dividend = 1) => {
    const elementTop = el.getBoundingClientRect().top;

    return (
        elementTop <= (window.innerHeight || document.documentElement.clientHeight) / dividend
    );
};

const elementOutOfView = (el) => {
    const elementTop = el.getBoundingClientRect().top;

    return (
        elementTop > (window.innerHeight || document.documentElement.clientHeight)
    );
};

const displayScrollElement = (element) => {
    element.classList.add("scrolled");
};

const hideScrollElement = (element) => {
    element.classList.remove("scrolled");
};

const handleScrollAnimation = () => {
    scrollElements.forEach((el) => {
        if (elementInView(el, 1.1)) {
            displayScrollElement(el);
        } else if (elementOutOfView(el)) {
            hideScrollElement(el)
        }
    });
}

const initEvents = function () {
    window.addEventListener('scroll', () => {
        handleScrollAnimation();
    })
}

const initPageScrollAnimations = function () {
    initEvents();
    handleScrollAnimation();
}

export { initPageScrollAnimations };
