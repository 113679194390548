const shareButtons = document.getElementsByClassName('js-share-btn');
// const modalButton = document.querySelector(`[js-shareButton]`)
// const modalOverlay = document.querySelector(`[js-modal-overlay]`)
// const copyButton = document.querySelector(`[js-copy-button]`)
// const copyIsCopiedText = document.querySelector(`[js-is-copied]`)
// const checkedSVG = document.querySelector(`[js-check-svg]`)
// const showLink = document.querySelector(`[js-show-link]`)
// let showLinkText = document.querySelector(`[js-show-link-text]`)
// let copiedLink = "https://www.figma.com/file/Fs8Z05HE81VjJ8Q4ELrOlj/Share-Doc-Window?node-id=0%3A1"

const copyToClipboard = function(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);
    }

    if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        const textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();

        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        } catch (exception) {
            console.warn("Copy to clipboard failed.", exception);
            return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        } finally {
            document.body.removeChild(textarea);
        }
    }
}

const initEvents = function () {
    [...shareButtons].forEach(
        (shareButton: HTMLElement) => {
            shareButton.addEventListener("click", (event) => {
                event.preventDefault();
                if (shareButton.dataset.link) {
                    copyToClipboard(shareButton.dataset.link);

                    const closeModalEvent = new CustomEvent("closeModal", { detail: { modalId: 'share-photo-book' } });
                    window.dispatchEvent(closeModalEvent);
                    const flashMessageEvent = new CustomEvent("flashMessage", { detail: { message: 'Link copied' } });
                    window.dispatchEvent(flashMessageEvent);
                }
            });
        }
    );
}
const initShareLinks = function () {
    initEvents();
}

export { initShareLinks };
