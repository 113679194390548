let flashMessage: Flash | undefined;
let hiddenFlashOffset: string | undefined;
const CLASSES: { [key: string]: string } = {
    CONTAINER: 'flash-container',
    FLASH: 'flash-message',
    FLASH_ONLOAD: 'flash-message--onload',
    FLASH_CONTENT: 'flash-message__content',
    FLASH_LOGIN: 'flash-message--login',
    CLOSE_BUTTON: 'flash-message__close-button',
    INFO: 'flash-message--info',
    ERROR: 'flash-message--error',
    WARNING: 'flash-message--warning',
    ANIMATE_IN: 'flash-message--is-animating-in',
    ANIMATE_OUT: 'flash-message--is-animating-out',
    ANIMATE_UP: 'flash-message--is-animating-up',
};
const ATTRIBUTES: { [key: string]: string } = {
    STYLE: 'style',
    CSS_VARIABLE: '--element-height',
    ROLE: 'role',
    ROLE_ALERT: 'alert',
    ROLE_STATUS: 'status',
};
const EVENTS: { [key: string]: string } = {
    CLICK: 'click',
    ANIMATION_END: 'animationend',
    FLASH_HIDING: 'flash:hiding',
    DOM_READY: 'DOMContentLoaded',
};
const FLASHTYPES: { [key: string]: string } = {
    INFO: 'info',
    LOGIN: 'login',
    WARNING: 'warning',
    ERROR: 'error',
}

const wrapperNode: HTMLElement | null  = document.querySelector(`.${CLASSES.CONTAINER}`);
const templateNode: HTMLElement | null = document.querySelector(`.${CLASSES.FLASH}`);

const flash_messages: Flash[] = [];
const flash_helpers = {
    removeMessage: function(hiddenMessage: { index: number; height: string }) {
        (flash_messages.slice(hiddenMessage.index + 1)).forEach((flash) => {
            flash_helpers.shiftMessageDown(flash.node, hiddenMessage.height);
        });
        if (hiddenMessage.index !== -1) {
            flash_messages.splice(hiddenMessage.index, 1);
        }
    },

    shiftMessageDown: function (node: HTMLElement, height: string) {
        node.classList.add(CLASSES.ANIMATE_UP);
        node.style.setProperty(ATTRIBUTES.CSS_VARIABLE, height);
        node.addEventListener(EVENTS.ANIMATION_END, () => {
            node.removeAttribute(ATTRIBUTES.STYLE);
            node.classList.remove(CLASSES.ANIMATE_UP);
        });
    },

    setHiddenFlashOffset: function (node: HTMLElement) {
        const height: number = node.offsetHeight;
        if (node.nextSibling) {
            const siblingMargin: number = (parseFloat(
                window.getComputedStyle(node.nextSibling as HTMLElement).marginBottom
            ));
            hiddenFlashOffset = `${height + siblingMargin}px`;
        }
    }
}

class Flash {
    message: string;
    node: HTMLElement;
    isError: boolean;
    domEl: HTMLElement | undefined;
    contentNode: Element;
    closeButton: Element;
    wrapperNode: HTMLElement | null;
    timeout: number | null;
    type: string;
    duration: number | null;

    //{isError: boolean; domEl: any; type: string}
    constructor(message: string, options?: { noticeDuration?: number; isError: boolean; domEl?: HTMLElement, type: string }) {
        const defaults = { isError: false, noticeDuration: 4000, domEl: templateNode!.cloneNode(true) as HTMLElement, type: 'info' };
        options = { ...defaults, ...options };

        this.message = message;
        if (options.domEl) {
            this.node = options.domEl;
        } else {
            this.node = templateNode!.cloneNode(true) as HTMLElement;
        }
        this.isError = options.isError!;
        this.domEl = options.domEl;
        this.contentNode = this.node.querySelector(`.${CLASSES.FLASH_CONTENT}`)!;
        this.closeButton = this.node.querySelector(`.${CLASSES.CLOSE_BUTTON}`)!;
        this.wrapperNode = wrapperNode;
        this.timeout = null;
        this.type = options.type ?? FLASHTYPES.INFO;
        this.duration = this.isError ? null : options.noticeDuration!;
    }

    show() {
        flash_messages.push(this);
        this.closeButton.addEventListener(EVENTS.CLICK, () => {
            this.hide();
        });

        this.node.classList.add(CLASSES[this.type.toUpperCase()]);
        if (this.isError) {
            this.contentNode.setAttribute(ATTRIBUTES.ROLE, ATTRIBUTES.ROLE_ALERT);
        } else {
            this.contentNode.setAttribute(ATTRIBUTES.ROLE, ATTRIBUTES.ROLE_STATUS);
            this.timeout = setTimeout(() => this.hide(), this.duration!);
        }
        if (this.domEl === false) {
            this.contentNode.textContent = this.message;
        }
        this.wrapperNode!.appendChild(this.node);
        (this.contentNode as HTMLElement).focus();
        this.node.classList.add(CLASSES.ANIMATE_IN);
        this.node.addEventListener(EVENTS.ANIMATION_END, () => {
            this.node.classList.remove(CLASSES.ANIMATE_IN);
        });
    }

    hide() {
        flash_helpers.setHiddenFlashOffset(this.node);
        document.dispatchEvent(new CustomEvent(EVENTS.FLASH_HIDING, {
            detail: {
                index: flash_messages.indexOf(this),
                height: hiddenFlashOffset,
            },
        }));
        this.node.classList.add(CLASSES.ANIMATE_OUT);
        this.node.addEventListener(EVENTS.ANIMATION_END, () => {
            this.node.classList.remove(CLASSES.ANIMATE_OUT);
            this.wrapperNode!.removeChild(this.node);
        });
    }
}

document.addEventListener(EVENTS.FLASH_HIDING, (event: CustomEvent) => {
    const hiddenMessage = {
        index: event.detail.index,
        height: event.detail.height,
    };
    flash_helpers.removeMessage(hiddenMessage);
});

const initFlashMessagesFromPageLoad = function () {
    if (!wrapperNode) {
        return;
    }
    const domFlashMessages: NodeListOf<HTMLElement> = wrapperNode.querySelectorAll(`.${CLASSES.FLASH_ONLOAD}`);
    [...domFlashMessages].forEach(
        (domFlashMessage: HTMLElement) => {
            const contentNode = domFlashMessage.querySelector(`.${CLASSES.FLASH_CONTENT}`) as HTMLElement;
            if (contentNode) {
                const type: string = domFlashMessage.dataset.type!;
                flashMessage = new Flash(contentNode.textContent!, {
                    domEl: domFlashMessage,
                    isError: type === 'error',
                    type: type,
                });
                flashMessage.show();
            }
        }
    );
}

const listenToLivewire = function () {
    window.addEventListener('flashMessage', (event: CustomEvent) => {
        flashMessage = new Flash(event.detail.message, {
            isError: (event.detail.type === FLASHTYPES.ERROR),
            type: event.detail.type,
        });
        flashMessage.show();
    });
    window.addEventListener('flashMessageLoginRegister', () => {
        const domFlashMessage = document.querySelector(`.${CLASSES.FLASH_LOGIN}`) as HTMLElement;
        if (!domFlashMessage) {
            return;
        }
        domFlashMessage.classList.remove('hidden');
        wrapperNode!.appendChild(domFlashMessage);
        flashMessage = new Flash('', {
            isError: false,
            type: FLASHTYPES.LOGIN,
            domEl: domFlashMessage,
            noticeDuration: 10000
        });
        flashMessage.show();
    });
}

const initFlashMessages = function () {
    initFlashMessagesFromPageLoad();
    listenToLivewire();
}

export { initFlashMessages };
