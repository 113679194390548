const openModalButtons = document.getElementsByClassName('js-open-modal-btn');
const closeModalButtons = document.getElementsByClassName('js-close-modal-btn');

const openModal = function (modalId: string) {
    const modalElement = document.getElementById(modalId);
    if (modalElement) {
        modalElement.classList.remove('hidden');
    }
}

const closeModal = function (modalElement: Element) {
    modalElement.classList.add('hidden');
}

const initEvents = function () {
    [...openModalButtons].forEach(
        (openModalButton: HTMLElement) => {
            openModalButton.addEventListener("click", (event) => {
                event.preventDefault();
                if (openModalButton.dataset.target) {
                    openModal(openModalButton.dataset.target);
                }
            });
        }
    );
    [...closeModalButtons].forEach(
        (closeModalButton: HTMLElement) => {
            closeModalButton.addEventListener("click", (event) => {
                event.preventDefault();
                const container = closeModalButton.closest('.js-modal');
                if (container) {
                    closeModal(container);
                }
            });
        }
    );

    window.addEventListener('closeModal', (event: CustomEvent) => {
        const modalElement = document.getElementById(event.detail.modalId);
        if (modalElement) {
            closeModal(modalElement);
        }
    });
}

const initModal = function () {
    initEvents();
}

export { initModal };
