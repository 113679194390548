import Dropzone from "dropzone";
import axios from "axios";

const dropzoneForms = document.getElementsByClassName('js-dropzone');
Dropzone.autoDiscover = false;

const initDropzone = function (dropzoneForm: HTMLFormElement) {
    const maxFiles = parseInt(dropzoneForm.dataset.maxFiles ?? '100');
    const uploadedFiles = dropzoneForm.getElementsByClassName('js-preview-image-upload');
    let totalFilesCanUpload:number = maxFiles - uploadedFiles.length;

    const myDropzone = new Dropzone(dropzoneForm, {
        parallelUploads: 3,
        addRemoveLinks: true,
        thumbnailWidth: 165,
        thumbnailHeight: 245,
        maxFilesize: 15,
        maxFiles: totalFilesCanUpload,
        filesizeBase: 1500,
        acceptedFiles: ".jpg,.jpeg,.png,.webp,.gif",
        success( file, response ){
            if (response.success) {
                file.previewElement.querySelector("a.dz-remove").dataset.dzRemove = response.success;
            }
        },
        thumbnail: function (file: { previewElement: HTMLElement, name: string }, dataUrl: string) {
            if (file.previewElement) {
                file.previewElement.classList.remove("dz-file-preview");
                const images = file.previewElement.querySelectorAll("[data-dz-thumbnail]");
                for (let i = 0; i < images.length; i++) {
                    const thumbnailElement = images[i] as HTMLImageElement;
                    thumbnailElement.alt = file.name;
                    thumbnailElement.src = dataUrl;
                }
                setTimeout(function () {
                    file.previewElement.classList.add("dz-image-preview");
                }, 1);
            }
        },
    });

    function updateMaxFiles(){
        const dropzoneMessage = dropzoneForm.querySelector('.dz-message') as HTMLElement;
        if (totalFilesCanUpload <= 0) {
            dropzoneMessage.classList.add('hidden');
        } else {
            dropzoneMessage.classList.remove('hidden');
        }
        myDropzone.options.maxFiles = totalFilesCanUpload;
    }
    updateMaxFiles();

    function removeFile(filename: string){
        if(filename && filename !== "") {
            axios.delete(dropzoneForm.action, {
                data: {filename: filename},
            });
        }
        totalFilesCanUpload++;
        updateMaxFiles();
    }

    const removeFileButtons =  dropzoneForm.getElementsByClassName('js-remove-uploaded_file');
    [...removeFileButtons].forEach(
        (removeFileButton: HTMLElement) => {
            removeFileButton.addEventListener("click", (event) => {
                event.preventDefault();
                const filename = removeFileButton.dataset.dzRemove;
                if (filename) {
                    removeFile(filename);
                    Livewire.dispatch('image-removed', { filename: filename })
                    const imageContainer = removeFileButton.closest(".js-preview-image-upload") as HTMLElement;
                    if(imageContainer) {
                        imageContainer.remove();
                    }
                }
            });
        }
    );

    myDropzone.on("removedfile", function(file: { previewElement: HTMLElement, name: string }) {
        const dzRemove = (file.previewElement.querySelector("a.dz-remove") as HTMLElement)?.dataset.dzRemove ?? file.name;
        removeFile(dzRemove);
    });

    myDropzone.on("addedfile", function(file: { previewElement: HTMLElement, name: string }) {
        Livewire.dispatch('image-added', { file: file });
        totalFilesCanUpload--;
    });

    myDropzone.on('error', function(file: { previewElement: HTMLElement, name: string }, response: object) {
        if (!(response && response.hasOwnProperty('message'))) {
            return;
        }
        const errorMessage = file.previewElement.querySelector('.dz-error-message') as HTMLElement;
        if (response && response.message === 'Unauthenticated.') {
            const flashMessageEvent = new CustomEvent("flashMessageLoginRegister");
            window.dispatchEvent(flashMessageEvent);
            errorMessage.innerHTML = 'You need to be logged in to upload files.';

            return;
        }
        if (!errorMessage) {
            return;
        }
        if ((response as { exception?: string }).exception === 'Illuminate\\Http\\Exceptions\\PostTooLargeException') {
            errorMessage.innerHTML = 'The file is too large. Maximum file size is 10MB.';
            return;
        }

        errorMessage.innerHTML = response.message;
    });
}

const initFilesUploader = function () {
    [...dropzoneForms].forEach(
        (dropzoneForm: HTMLFormElement) => {
            initDropzone(dropzoneForm);
    })
}

export { initFilesUploader };
