const container: HTMLElement | null = document.getElementById('js-photo-book');
let pageId: number = 0;
const pages: HTMLCollectionOf<Element> | null = container?.getElementsByClassName('js-template');
const totalPages: number = pages?.length ?? 0;
let oldPageId: number = 0;

function getFullscreenElement(): Element | null {
    return document.fullscreenElement
        || document.webkitFullscreenElement
        || document.mozFullscreenElement
        || document.msFullscreenElement;
}

function toggleFullscreen(): void {
    if (getFullscreenElement()) {
        document.exitFullscreen();
    } else {
        document.documentElement.requestFullscreen().catch(console.log);
    }
}

const iconButtonEvents = function (): void {
    const fullscreenButtons: HTMLCollectionOf<Element> = document.getElementsByClassName('js-photo-book-fullscreen');
    [...fullscreenButtons].forEach(
        (fullscreenButton: HTMLElement) => {
            fullscreenButton.addEventListener("click", toggleFullscreen);
        }
    );
}

function listenToKeys(keyboardEvent: KeyboardEvent): void {
    switch (keyboardEvent.key) {
        case "ArrowLeft":
            showPreviousPage();
            stopSlideShow();
            break;
        case "ArrowRight":
            showNextPage();
            stopSlideShow();
            break;
    }
}

function stopKeyListeners(): void {
    document.removeEventListener('keydown', listenToKeys);
}

function initKeyListeners(): void {
    document.addEventListener('keydown', listenToKeys);
}

function initLightboxListeners(): void {
    const lightboxButtons: HTMLCollectionOf<Element> = document.getElementsByClassName('js-lightbox');
    [...lightboxButtons].forEach(
        (lightboxButton: HTMLElement) => {
            lightboxButton.addEventListener("click", () => {
                stopKeyListeners();
            });
        }
    );

    document.addEventListener('lightboxClosed', function(customEvent: Event) {
        const pageNumber = findPageNumber(customEvent.detail);
        hidePage(oldPageId);
        oldPageId = pageId;
        pageId = pageNumber;
        showPage(pageId);
        initKeyListeners();
    }, false);
}

function initSlideShowListeners(): void {
    container.addEventListener("click", () => {
        stopSlideShow();
    });

    const startSlideShowButtons: HTMLCollectionOf<Element> = document.getElementsByClassName('js-photo-book-start-slide-show');
    [...startSlideShowButtons].forEach(
        (startSlideShowButton: HTMLElement) => {
            startSlideShowButton.addEventListener("click", (event: MouseEvent) => {
                event.stopPropagation();
                event.preventDefault();
                startSlideShow();
            });
        }
    );
}

function initEventListeners(): void {
    const nextPageButtons = document.getElementsByClassName('js-photo-book-next-page') as HTMLCollectionOf<HTMLElement>;
    for (const nextPageButton of nextPageButtons) {
        nextPageButton.addEventListener("click", (event: MouseEvent) => {
            event.preventDefault();
            showNextPage();
        });
    }

    const previousPageButtons = document.getElementsByClassName('js-photo-book-previous-page') as HTMLCollectionOf<HTMLElement>;
    for (const previousPageButton of previousPageButtons) {
        previousPageButton.addEventListener("click", (event: MouseEvent) => {
            event.preventDefault();
            showPreviousPage();
        });
    }

    initSlideShowListeners();
    initKeyListeners();
    initLightboxListeners();
    iconButtonEvents();
}

const pageIndicators = document.getElementsByClassName('js-photo-book-page-indicator') as HTMLCollectionOf<HTMLElement>;
function displayPageNumber(pageId: number): void {
    for (const pageIndicator of pageIndicators) {
        pageIndicator.innerHTML = pageId + ' / ' + totalPages;
    }
}

function findPageNumber(element: HTMLElement): number {
    const container = element.closest('.js-template') as HTMLElement;

    return container?.dataset.pageid ? parseInt(container.dataset.pageid) : 0;
}

function showPage(templatePageId: number): boolean {
    const template = container.querySelector('.js-template-' + templatePageId) as HTMLElement;
    if (!template) {
        return false;
    }

    template.classList.remove('hidden');

    const pageChangedEvent = new CustomEvent("pageChanged", {
        detail: {
            pageId: templatePageId
        }
    });
    window.dispatchEvent(pageChangedEvent);

    return true;
}

function hidePage(templatePageId: number): boolean {
    const template = container.querySelector('.js-template-' + templatePageId) as HTMLElement;
    if (!template) {
        return false;
    }

    template.classList.add('hidden');

    return true;
}

function showNextPage(): void {
    pageId++;

    if (pageId === totalPages) {
        stopSlideShow();
    }
    if (pageId > totalPages) {
        pageId = 1;
    }
    showPage(pageId);

    if (oldPageId > totalPages) {
        oldPageId = 1;
    }
    hidePage(oldPageId);
    displayPageNumber(pageId);

    oldPageId++;
}

function showPreviousPage(): void {
    pageId--;

    if (pageId < 1) {
        pageId = totalPages;
    }
    showPage(pageId);

    if (oldPageId < 1) {
        oldPageId = totalPages;
    }
    hidePage(oldPageId);
    displayPageNumber(pageId);

    oldPageId--;
}

let toSlideShow: ReturnType<typeof setInterval> | undefined;
function startSlideShow(): void {
    stopSlideShow();
    showNextPage();
    toSlideShow = setInterval(showNextPage, 5000);
}

function stopSlideShow(): void {
    if (toSlideShow) {
        clearInterval(toSlideShow);
    }
}

function init(): void {
    if (!container) {
        return;
    }

    initEventListeners();

    const templates = container.getElementsByClassName('js-template') as HTMLCollectionOf<HTMLElement>;
    if (templates.length === 0) {
        return;
    }

    startSlideShow();
}

init();
