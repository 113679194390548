import './bootstrap';

import './photoBook';

import.meta.glob([ '../img/**', ]);

import {initCommon} from'./common';
initCommon();

import {initPageScroll} from'./pageScroll';
initPageScroll();

import {initPassword} from'./password';
initPassword();

import {initCarousels} from'./carousel';
initCarousels();

import {initLightboxGallery} from'./lightboxGallery';
initLightboxGallery();

import {initFilesUploader} from'./filesUploader';
initFilesUploader();

import {initModal} from'./modal';
initModal();

import {initFlashMessages} from'./flashMessages';
initFlashMessages();

import {initShareLinks} from'./shareLink';
initShareLinks();

import {initNavigation} from'./navigation';
initNavigation();

import {initPageScrollAnimations} from'./pageScrollAnimations';
initPageScrollAnimations();

// import {initInput} from'./input';
// initInput();

import {initPrompt} from'./prompt';
initPrompt();

// import {initPreviewImage} from'./previewImage';
// initPreviewImage();
